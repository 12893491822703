import initFullstory from './fullstory'
import initPosthog from './posthog'

const runIntegrations = () => {
  if (process.env.CONFIG_ENV === 'production') {
    initFullstory()
  }
  initPosthog()
}

export default runIntegrations
